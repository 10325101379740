/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it


import jQuery from 'jquery';
import 'slick-carousel';
import 'foundation-sites';
import prevArrowImagePath from './static/prev.jpg'
import nextArrowImagePath from './static/next.jpg'
require( 'foundation-sites/scss/foundation.scss' );
// require( './src/styles/_style.scss');
require( 'slick-carousel/slick/slick.scss' );
require( 'slick-carousel/slick/slick-theme.scss' );



// Add custom js codes in 'onRouteUpdate' if you want the code run after render
export const onRouteUpdate = ({ location, prevLocation }) => {
    if(jQuery(".slider-container .single-slide").length > 1){
        jQuery(".slider-container").slick({
            slidesToShow: 1,
            autoplay: true,
            autoplaySpeed: 5000,
            dots: false,
            pauseOnHover: true,
            prevArrow: false,
            nextArrow: false
        })
    }

    if(jQuery(".slider-carousel__container .single-slider").length > 1){
        jQuery(".slider-carousel__container").slick({
            centerMode: true,
            centerPadding: '0px',
            slidesToShow: 3,
            draggable: false,
            prevArrow: '<button class="slick-prev slick-arrow" aria-label="Previous" type="button" style=""><img class="prev-img" src=" ' + prevArrowImagePath+ ' "/></button>',
            nextArrow: '<button class="slick-next slick-arrow" aria-label="Next" type="button" style=""><img class="next-img" src=" ' + nextArrowImagePath+ ' "/></button>',
            responsive: [
                {
                breakpoint: 768,
                settings: {
                    arrows: false,
                    centerMode: true,
                    centerPadding: '0px',
                    slidesToShow: 3,
                    draggable: true,
                }
                },
                {
                breakpoint: 480,
                settings: {
                    arrows: false,
                    centerMode: true,
                    centerPadding: '0px',
                    slidesToShow: 1,
                    draggable: true,
                }
                }
            ]
        })
    }

    document.querySelector(".hamburger-icon").addEventListener("click", () => {
        document.querySelector(".right-section-mobile").classList.toggle("actived")
    })
}